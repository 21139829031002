import { getIsLabelValue } from "basics/select";

import { RenderOption } from "../../duck/types";

const Option: RenderOption = ({ value }) => {
  if (getIsLabelValue(value)) {
    return value.label;
  }

  return String(value);
};

export default Option;
