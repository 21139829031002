import { getIsLabelValue } from "basics/select";

import { RenderButton } from "../../duck/types";

const Button: RenderButton = ({ selected }) => {
  if (!selected) {
    return "Choose option";
  }

  if (getIsLabelValue(selected)) {
    return selected.label;
  }

  return selected as string;
};

export default Button;
