export const LIST_MAX_HEIGHT = 516;

export const SCROLLBAR_PROPS = {
  contentProps: { style: { padding: "16px 0", display: "block" } },
};

export const LIST_SHADOW = {
  bottom: `0 0 48px -2px rgba(61, 6, 82, 0.06), 0 2px 2px -1px rgba(61, 6, 82, 0.04)`,
  top: `0 0 48px -2px rgba(61, 6, 82, 0.06), 0 -2px 2px -1px rgba(61, 6, 82, 0.04)`,
};

export const LIST_OFFSET = 8;
