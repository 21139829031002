import { FC, SVGProps } from "react";
import joinClassNames from "classnames";

import Loader24Icon from "icons/loader24.svg?react";

import { Themes, Sizes } from "./duck/types";

import classes from "./styles/classes.module.scss";

interface Props extends SVGProps<SVGSVGElement> {
  themeName?: Themes;
  size?: Sizes;
}

const Loader: FC<Props> = ({
  themeName = "primary",
  size = "lg",
  className,
  ...props
}) => (
  <Loader24Icon
    data-theme={themeName}
    data-size={size}
    className={joinClassNames(
      classes.loaderThemePresets,
      classes.loaderSizePresets,
      classes.loader,
      className,
    )}
    {...props}
  />
);

export default Loader;
