import { ReactNode } from "react";

import NativeScrollbar from "components/scrollbar";

import { LIST_MAX_HEIGHT, SCROLLBAR_PROPS } from "../../duck/constants";

interface Props {
  children: ReactNode;
  maxHeight?: number | string;
}

const Scrollbar = ({ children, maxHeight = LIST_MAX_HEIGHT }: Props) => (
  <NativeScrollbar
    translateContentSizeYToHolder
    contentProps={SCROLLBAR_PROPS.contentProps}
    style={{ maxHeight }}
  >
    {children}
  </NativeScrollbar>
);

export default Scrollbar;
