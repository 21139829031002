import { FC } from "react";
import makeBlockiesUrl from "blockies-base64-svg";
import joinClassNames from "classnames";

import Icon from "./Icon";
import { BaseProps, Sizes } from "./duck/types";

import classes from "./styles/classes.module.scss";

interface Props extends BaseProps {
  className?: string;
  size?: Sizes;
  address: Address["address"];
}

const AddressIcon: FC<Props> = ({ address, className, size, ...restProps }) => (
  <Icon
    src={makeBlockiesUrl(address)}
    data-size={size}
    className={joinClassNames(
      classes.sizePresets,
      classes.addressIcon,
      className,
    )}
    {...restProps}
  />
);

export default AddressIcon;
