import { useMemo, useRef } from "react";

import { getPlacementStyles } from "./selectors";
import { Placement } from "./types";

export const usePlacement = <T extends HTMLElement>(
  placement: Placement,
  deps: unknown[],
) => {
  const triggerRef = useRef<T>(null);
  const listStyles = useMemo(
    () => getPlacementStyles(triggerRef.current, placement),
    ([placement] as unknown[]).concat(deps),
  );

  return {
    triggerRef,
    listStyles,
  };
};
