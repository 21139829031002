import { CSSProperties } from "react";
import isObject from "lodash/isObject";

import { GroupedOptions, OptionType, Placement, Value } from "./types";
import { LIST_MAX_HEIGHT, LIST_SHADOW, LIST_OFFSET } from "./constants";

export const getProcessedOption = <T>(option: T): LabelValue<T> | null => {
  if (!option) {
    return null;
  }

  if (getIsLabelValue(option)) {
    return option as LabelValue<T>;
  }

  return {
    label: (option as { id?: string })?.id || String(option),
    value: option as T,
  };
};

export const getIsGrouped = <T>(
  item: OptionType<T>,
): item is GroupedOptions<T> => isObject(item) && "title" in item;

export const getIsLabelValue = (item: unknown): item is LabelValue<Value> =>
  isObject(item) && "label" in item && "value" in item;

export const getPlacementStyles = <T extends HTMLElement>(
  trigger: T | null,
  placement: Placement,
): CSSProperties => {
  if (!trigger) {
    return {};
  }

  const offset = LIST_OFFSET * 2;
  const { innerHeight } = window;
  const { y, height } = trigger.getBoundingClientRect();
  const toBottom = innerHeight - (y + height) - offset;
  const toTop = y - offset;
  if (placement !== "auto") {
    if (placement === "top") {
      return {
        maxHeight: toTop,
        bottom: height,
        boxShadow: LIST_SHADOW.top,
        marginBottom: 8,
      };
    }

    return {
      maxHeight: toBottom,
      boxShadow: LIST_SHADOW.bottom,
      marginTop: LIST_OFFSET,
    };
  }

  if (toBottom >= LIST_MAX_HEIGHT) {
    return { boxShadow: LIST_SHADOW.bottom, marginTop: LIST_OFFSET };
  }

  if (y >= LIST_MAX_HEIGHT) {
    return {
      bottom: height,
      boxShadow: LIST_SHADOW.top,
      marginBottom: LIST_OFFSET,
    };
  }

  return toBottom > toTop
    ? {
        maxHeight: toBottom,
        boxShadow: LIST_SHADOW.bottom,
        marginTop: LIST_OFFSET,
      }
    : {
        maxHeight: toTop,
        bottom: height,
        boxShadow: LIST_SHADOW.top,
        marginBottom: 8,
      };
};

export const defaultFilter = <T>(q: string, { label }: LabelValue<T>) =>
  label.toLowerCase().includes(q);
